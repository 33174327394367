<template>
  <div class="flex">
    <perfect-scrollbar ref="scrollSection" class="box-border flex !h-[554px] shrink-0">
      <div class="flex w-[360px] flex-col gap-16 p-24">
        <g-loading-placeholder v-for="i in 7" :key="i" cls="!rounded-xl" gray width="100%" height="64px" />
      </div>
    </perfect-scrollbar>
    <div class="w-full p-24">
      <g-loading-placeholder cls="!rounded-xl" gray width="100%" height="500px" />
    </div>
  </div>
</template>
